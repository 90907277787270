@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
.global-card {
  display: flex;
  flex-direction: column;
  margin: 15px;
  margin-bottom:20px;
  width: 350px;
  height: 350px;
  cursor: pointer;
  .ant-card-body {
    width: 100%;
    height: 100%;

    .header-card{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-card-title {
        font-family: 'Open Sans:400', sans-serif;
        font-size: 1.8em;
        font-weight: bold;
        padding-left: 15%;
      }
      img {
        max-height: 100px;
        max-width: 100px;
      }
    }
    .content-card{
      display: flex;
      flex-direction: column;
      margin: 5%;

      .content-card-title {
        font-family: 'Open Sans:400', sans-serif;
        font-size: 1.4em;
        font-weight: bold;
      }

      p{
        margin-top: 2%;
        text-align: justify;
        font-family: 'Open Sans:300', sans-serif;
      }
    }
  }
}

.ant-card-bordered {
  border-color: #FF8900;
}

.global-card :hover {
  background-color: #FF8900;
}

.global-card:active {
  transform: scale(.95);
}
