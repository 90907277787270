@import '~antd/dist/antd.css';


.global-ui-description {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .background {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/backgournd.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(45%)
  }
  .dashboard {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .header-container {
      height: 20%;
      width: 100%;
    }

    .back-button-container {
      display: flex;
      width: 100%;
      .back-button {
        margin: 4% 0 0 1%;
      }
      .ant-btn, .ant-btn:active, .ant-btn:focus {
        background-color: #FF8900;
        border-color: #FF8900;
        color: white;
      }
    }

    .container-description {
      height: 50%;
      width: 80%;
      align-items: center;
      display: flex;
      .description-image {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          cursor: pointer;
        }
      }

      .content-description {
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        width: 50%;
        height: 80%;
        justify-content: center;
        .header-description {
          display: flex;
          height: 20%;
          width: 100%;
          align-items: center;
          justify-content: space-around;

          img {
            height: 100%;
          }

          .title-app {
            color: white;
            font-size: 2em;
          }
        }

        .description-text {
          display: flex;
          flex-direction: column;
          margin-top: 5%;
          height: 70%;
          width: 100%;
          color: white;
          align-items: center;
          .global-container-button {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            .ant-btn, .ant-btn:active, .ant-btn:focus {
              background-color: #FF8900;
              border-color: #FF8900;
              color: white;
            }
            .container-button {
              display: flex;
              width: 50%;
              align-items: center;
              justify-content: space-evenly;
              Title {
                color: white;
              }
            }
          }
          .content-store {
            margin-top: 2%;
            display: flex;
            width: 100%;
            height: 20%;
            justify-content: space-between;
            .logo-store {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  }
@media screen and (max-width: 900px) {
  .global-ui-description {

    .dashboard {

      .back-button-container {
        margin-bottom: 2%;
      }

      .container-description {
        display: flex;
        flex-direction: column;
        margin-top: 1%;

        .description-image {
          width: 100%;
          justify-content: center;
          img {
            height: 100%;
            width: 80%;
          }
        }

        .content-description {
          width: 100%;
          margin: 1.5% 0 0 0;

          .description-text {

            .global-container-button {
              display: flex;
              flex-direction: column;
              width: 100%;

              .container-button{
                width: 100%;
                margin: 0.5%;
                display: flex;
                .content-button {
                  width: 50%;
                }
              }
            }
          }
        }
        .content-store {

        }

      }
    }
  }
}
