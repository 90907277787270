@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

.global-card-info {
  background-color: black;
  opacity: 0.7;
  border: none;
  display: flex;
  flex-direction: column;
  margin: 15px;
  margin-bottom:20px;
  max-width: 350px;
  max-height: 350px;

  .header-card-info {
    display: flex;
    height: 20%;
    justify-content: space-between;
    align-items: center;
    margin: 5%;
    .header-info-title {
      font-family: 'Open Sans:400', sans-serif;
      font-weight: bold;
      font-size: 1.7em;
      color: white;
    }
    img {
      max-height: 60px;
    }
  }

  .content-card-info {
    display: flex;
    flex-direction: column;
    margin: 1%;
    height: 20%;
    p{
      font-family: 'Open Sans:300', sans-serif;
      text-align: justify;
      color: white;
    }
  }

  .link-card-info {
    display: flex;
    flex-direction: column;
    height: 40%;
    margin: 1% 0;
    .link-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        display: flex;
        align-items: center;
        width: 50%;
        margin: 2% 0;
        img {
          max-height: 40px;
        }
      }
      .button-access {
        background-color: #FF8900;
        border-color: #FF8900;
        color: white;
      }
      .button-access:active {
        transform: scale(.95);
      }
    }
  }
}
