@import '~antd/dist/antd.css';

.global-ui {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .background {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url("../assets/backgournd.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(45%)
  }
  .dashboard {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;


    .header-container {
      top: 0;
      left: 0;
      height: 20%;
      width: 100%;
    }

    .select-container {
      height: 10%;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .ant-input {
        background-color: rgba(0, 0, 0, 0.1);
        color: #ffffff
      }

      .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
        border-color: #FF8900;
      }

      .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button, .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
        background-color: #FF8900;
        border-color: #FF8900;
      }
    }



    .card-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      padding-right: 20px;
      padding-left: 20px;
      height: 60%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 900px){
  .global-ui {
    .dashboard {
      .select-container {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
